<template>
  <li-area-select v-model="defultAddress"></li-area-select>
</template>

<script>
  import liAreaSelect from "@/components/liAreaSelect4";
  export default {
    components: {
      liAreaSelect
    },
    data(){
      return {
        //默认值设置，可为空
        defultAddress: {
          sheng: '广东省',
          shi: '',
          qu: '',
          jd: ''
        },
      }
    },
    
  }
</script>

<style>
</style>
